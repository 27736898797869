import * as React from 'react';
import { useEffect, useState } from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import * as Images from '../../assets/images/temp';
import * as SVG from '../../assets/images/svg';

import './index.scss';

const TeamList = () => {
	const data = useStaticQuery(graphql`
		query MyQuery {
			allMember {
				nodes {
					avatar
					alt
					description
					name
					position
					socials {
						name
						socialSrc
						alt
					}
				}
			}
		}
	`);

	const [offset, setOffset] = useState(0);

	useEffect(() => {
		function handleScroll() {
			setOffset(window.pageYOffset);
		}
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const allMembers = data.allMember.nodes;

	return (
		<section
			id="team"
			className={`c-team__wrapper`}
			style={{
				transform: `translateY(${offset * -0.2}px)`,
				backgroundColor: '',
			}}
		>
			<h2 className="c-team__title">Three11 Team</h2>

			<div className="c-team__members">
				{allMembers.map((member, index) => {
					return (
						<div className="c-team__member" key={index}>
							<figure className="c-team__member-image">
								<img
									src={Images[`${member.avatar}`]}
									alt={`${member.alt}`}
								/>
							</figure>

							<div className="c-team__member-description">
								<h4 className="c-team__member-title">
									{member.name}
								</h4>
								<h5 className="c-team__member-sub-title">
									{member.position.join(' / ')}
								</h5>

								<div
									className="c-team__member-text"
									style={{ backgroundColor: '' }}
								>
									{member.description}
								</div>
							</div>

							<div className="c-team__member-socials">
								<ul className="c-socials">
									{member.socials.map(
										(social, index) =>
											social.socialSrc && (
												<li
													className="c-social"
													key={index}
												>
													<a
														href={social.socialSrc}
														target="_blank"
														rel="noreferrer noopener"
													>
														<img
															src={
																SVG[
																	`${social.name}`
																]
															}
															alt={`${social.alt}`}
														/>
													</a>
												</li>
											)
									)}
								</ul>
							</div>
						</div>
					);
				})}
			</div>
		</section>
	);
};

export default TeamList;
