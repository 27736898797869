import * as React from 'react';

import './index.scss';

export const Button = ({ children, type, className }) => {
	return (
		<button className={`c-btn ${className}`} type={type}>
			{children}
		</button>
	);
};

export default Button;
