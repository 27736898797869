import * as React from 'react';
import { useEffect, useState } from 'react';

import { Button } from '../button';

import './index.scss';

export const Form = () => {
	const [offset, setOffset] = useState<number>(0);

	useEffect(() => {
		function handleScroll() {
			setOffset(window.pageYOffset);
		}
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className="c-form">
			{/* <Inner> */}
			<h2>Send Us a message</h2>

			<form action="https://formspree.io/f/myybeppg" method="POST">
				<div className="c-form__body">
					<div className="c-form__row">
						<div className="c-form__field">
							<input
								name="name"
								type="text"
								id="name"
								placeholder="Your Name (required)"
								required={true}
							/>
						</div>

						<div className="c-form__field">
							<input
								name="_replyto"
								id="email"
								placeholder="Your Email (required)"
								required={true}
							/>
						</div>
					</div>

					<div className="c-form__row">
						<div className="c-form__field c-form__field--full-width">
							<textarea
								name="message"
								id="message"
								placeholder="Your Message"
							/>
						</div>
					</div>
				</div>

				<div className="c-form__actions">
					<Button className="c-btn--submit" type="submit">
						Send
					</Button>
				</div>
			</form>
			{/* </Inner> */}
		</div>
	);
};

export default Form;
