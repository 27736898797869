import React from 'react';
import Hero from '../components/hero';
import ProjectsSection from '../components/projectsSection';
import About from '../components/about';
import Contact from '../components/contact';
import Footer from '../components/footer/index';
import TeamSection from '../components/teamSection';
import App from '../components/app/index';
import SEO from '../components/seo';

const Cara = () => (
	<App>
		<SEO>
			<title>Welcome</title>
		</SEO>
		<Hero />
		<ProjectsSection />
		<About />
		<TeamSection />
		<Contact />
		<Footer />
	</App>
);

export default Cara;
