import * as React from 'react';
import { useEffect, useState } from 'react';

import './index.scss';

export const App = ({ children }) => {
	const [docHeight, setDocHeight] = useState<number>();

	const [isSmallDesktop, setIsSmallDesktop] = React.useState<boolean>(false);
	const [isTabletLandscape, setIsTabletLandscape] = React.useState<boolean>(
		false
	);
	const [isTabletPortrait, setIsTabletPortrait] = React.useState<boolean>(
		false
	);
	const [isMobile, setIsMobile] = React.useState<boolean>(false);

	// The height of the document multiply by this magic number gives the new Height of the document, which is lower(because white space at the bottom of the doc is removed). It needs to be adjust if new elements/content is added.
	//let magicNumber = 0.71;
	const [magicNumber, setMagicNumber] = React.useState(0.8);

	useEffect(() => {
		const windowWidth: number = window.innerWidth;
		setIsSmallDesktop(windowWidth >= 1366);
		setIsTabletLandscape(windowWidth >= 1024 && windowWidth <= 1280);
		setIsTabletPortrait(windowWidth >= 768 && windowWidth < 1024);
		setIsMobile(windowWidth < 768);

		//TODO Convert if-else to Switch case!

		if (isSmallDesktop) {
			setMagicNumber(0.99);
		} else if (isTabletLandscape) {
			setMagicNumber(1);
		} else if (isTabletPortrait) {
			setMagicNumber(0.97);
		} else if (isMobile) {
			setMagicNumber(0.97);
		} else {
			setMagicNumber(0.902);
		}

		let pixelsToRemove: number =
			document.body.scrollHeight -
			document.body.scrollHeight * magicNumber;
		setDocHeight(document.body.scrollHeight - pixelsToRemove);
	}, [
		isSmallDesktop,
		isTabletPortrait,
		isTabletLandscape,
		isMobile,
		magicNumber,
	]);

	return (
		<div className="c-app" style={{ height: `${docHeight}px` }}>
			{children}
		</div>
	);
};

export default App;
