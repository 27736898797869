export const ImageXPND = require('./image-xpnd.jpg');
export const ImageEBOOK = require('./image-ebook.jpg');
export const ImageKINETIK = require('./image-kinetik.jpg');
export const ImageRETIREMENT = require('./image-retirement.jpg');
export const ImageFERTILITY = require('./image-fertility.jpg');
export const ImagePATERNALISM = require('./image-paternalism.jpg');
export const ImageIMGN = require('./image-imgn.jpg');
export const ImageFLOWS = require('./image-flows.jpg');
export const ImageNIM = require('./image-nim.jpg');
export const ImagePORTFOLIO = require('./image-portfolio.jpg');
export const ImageBECKYBALL = require('./image-beckyball.jpg');
export const ImageMALAIKAPICTURES = require('./image-malaika-pictures.jpg');
export const ImagePASKOE = require('./image-pascoe.jpg');
export const ImageVFT = require('./image-varna-food-tours.jpg');
export const ImagePERSONALSITE = require('./image-personal-site.jpg');
export const ImageCAH = require('./image-cah.jpg');
export const ImageARIELY = require('./image-ariely.jpg');
export const ImageJAG = require('./image-jag.jpg');
export const ImageAvatarMartin = require('./avatar-martin.jpg');
export const ImageAvatarAlexander = require('./avatar-alexander.jpg');
export const ImageAvatarAtanas = require('./avatar-atanas.jpg');
export const ImageAvatarRashko = require('./avatar-rashko2.jpg');
