import * as React from 'react';
import { useEffect, useState } from 'react';

import Inner from '../inner';

import SVG from '../svg';
import { UpDown, UpDownWide } from '../../styles/utilities/animations';
// @ts-ignore
import AboutMDX from '../../sections/about';

import './index.scss';

const About = () => {
	const [offset, setOffset] = useState(0);

	useEffect(() => {
		function handleScroll() {
			setOffset(window.pageYOffset);
		}
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div
			className="c-about"
			style={{
				transform: `translateY(${offset * -0.5}px)`,
				backgroundColor: '',
			}}
		>
			<Inner>
				<h2>About Three11</h2>
				<AboutMDX />
			</Inner>

			<div className="c-icons">
				<UpDown>
					<SVG
						icon="box"
						hiddenMobile
						width={6}
						color="icon_blue"
						left="50%"
						top="75%"
					/>
					<SVG
						icon="upDown"
						hiddenMobile
						width={8}
						color="icon_darkest"
						left="70%"
						top="20%"
					/>
					<SVG
						icon="triangle"
						width={8}
						stroke
						color="icon_darkest"
						left="25%"
						top="5%"
					/>
					<SVG
						icon="upDown"
						hiddenMobile
						width={24}
						color="icon_orange"
						left="80%"
						top="80%"
					/>
				</UpDown>
				<UpDownWide>
					<SVG
						icon="arrowUp"
						hiddenMobile
						width={16}
						color="icon_purple"
						left="5%"
						top="80%"
					/>
					<SVG
						icon="triangle"
						width={12}
						stroke
						color="icon_brightest"
						left="95%"
						top="50%"
					/>
					<SVG
						icon="circle"
						hiddenMobile
						width={6}
						color="icon_brightest"
						left="85%"
						top="15%"
					/>
					<SVG
						icon="upDown"
						hiddenMobile
						width={8}
						color="icon_darkest"
						left="45%"
						top="10%"
					/>
				</UpDownWide>
				<SVG
					icon="circle"
					hiddenMobile
					width={6}
					color="icon_brightest"
					left="4%"
					top="20%"
				/>
				<SVG
					icon="circle"
					width={12}
					color="icon_darkest"
					left="70%"
					top="60%"
				/>
				<SVG
					icon="box"
					width={6}
					color="icon_orange"
					left="10%"
					top="10%"
				/>
				<SVG
					icon="box"
					width={12}
					color="icon_darkest"
					left="20%"
					top="30%"
				/>
				<SVG
					icon="hexa"
					width={8}
					stroke
					color="icon_darkest"
					left="80%"
					top="70%"
				/>
			</div>
		</div>
	);
};

export default About;
