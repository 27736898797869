/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';

import './index.scss';

type InnerProps = {
	className?: string;
	children: React.ReactNode;
	style?: any;
};

const Inner = ({ className = `c-inner`, children, style }: InnerProps) => {
	return (
		<div
			style={style}
			//sx={{ width: [`full`, `full`, `full`, `full`, `full`, `2/3`], textAlign: `left` }}
			className={className}
		>
			{children}
		</div>
	);
};

export default Inner;
