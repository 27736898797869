import * as React from 'react';

import './index.scss';

export const Cta = () => {
	return (
		<div className="c-cta">
			<h2> Get in touch </h2>

			<p>
				Say <a href="mailto:hello@three-11.com">Hi</a> or find us on{' '}
				<a
					href="https://www.linkedin.com/company/three11/"
					target="_blank"
				>
					Linkedin
				</a>{' '}
				or{' '}
				<a href="https://github.com/three11" target="_blank">
					Github
				</a>
			</p>
		</div>
	);
};

export default Cta;
