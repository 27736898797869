import * as React from 'react';
import { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import Inner from '../inner';

import SVG from '../svg';
import { UpDown, UpDownWide, waveAnimation } from '../../styles/utilities/animations';

// @ts-ignore
import ContactMDX from '../../sections/contact';
import Form from '../form';

import './index.scss';

const InnerWave = styled.div`
	path {
		${waveAnimation(`20s`)};
	}
`;

const Contact = () => {
	const [offset, setOffset] = useState(0);

	useEffect(() => {
		function handleScroll() {
			setOffset(window.pageYOffset);
		}
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className="c-contact" style={{ transform: `translateY(${offset * -0.4}px)`, backgroundColor: '' }}>
			<Inner style={{ transform: `translateY(${offset * -0.1}px)`, backgroundColor: '' }}>
				<div className="c-contact__container">
					{/* <h2>Get in touch</h2> */}
					<ContactMDX />
					<Form />
				</div>
			</Inner>

			<div className="c-icons">
				<UpDown>
					<SVG icon="upDown" hiddenMobile width={8} color="icon_darkest" left="70%" top="20%" />
					<SVG icon="triangle" width={8} stroke color="icon_darkest" left="25%" top="5%" />
				</UpDown>
				<UpDownWide>
					<SVG icon="triangle" width={12} stroke color="icon_brightest" left="95%" top="50%" />
					<SVG icon="circle" width={6} color="icon_brightest" left="85%" top="15%" />
					<SVG icon="upDown" hiddenMobile width={8} color="icon_darkest" left="45%" top="10%" />

					<SVG icon="arrowUp" hiddenMobile width={16} color="icon_blue" left="80%" top="10%" />
					<SVG icon="triangle" width={12} stroke color="icon_brightest" left="90%" top="50%" />
					<SVG icon="circle" width={16} color="icon_darker" left="70%" top="90%" />
					<SVG icon="triangle" width={16} stroke color="icon_darkest" left="30%" top="65%" />
					<SVG icon="cross" width={16} stroke color="icon_pink" left="28%" top="15%" />
					<SVG icon="circle" width={6} color="icon_darkest" left="75%" top="10%" />
					<SVG icon="upDown" hiddenMobile width={8} color="icon_darkest" left="45%" top="10%" />
				</UpDownWide>
				<SVG icon="circle" width={6} color="icon_brightest" left="4%" top="20%" />
				<SVG icon="circle" width={12} color="icon_darkest" left="70%" top="60%" />
				<SVG icon="box" width={12} color="icon_darkest" left="20%" top="30%" />
				<SVG icon="hexa" width={8} stroke color="icon_darkest" left="80%" top="70%" />
				<UpDown>
					<SVG icon="triangle" hiddenMobile width={48} stroke color="icon_orange" left="10%" top="20%" />
					<SVG icon="hexa" width={48} stroke color="icon_red" left="10%" top="70%" />
					<SVG icon="box" width={6} color="icon_darker" left="60%" top="15%" />
				</UpDown>

				<SVG icon="circle" hiddenMobile width={24} color="icon_darker" left="5%" top="70%" />
				<SVG icon="circle" width={6} color="icon_darkest" left="4%" top="20%" />
				<SVG icon="circle" width={12} color="icon_darkest" left="50%" top="60%" />
				<SVG icon="upDown" width={8} color="icon_darkest" left="95%" top="90%" />
				<SVG icon="upDown" hiddenMobile width={24} color="icon_darker" left="40%" top="80%" />
				<SVG icon="triangle" width={8} stroke color="icon_darker" left="25%" top="5%" />
				<SVG icon="circle" width={64} color="icon_green" left="95%" top="5%" />
				<SVG icon="box" hiddenMobile width={64} color="icon_purple" left="5%" top="90%" />
				<SVG icon="box" width={6} color="icon_darkest" left="10%" top="10%" />
				<SVG icon="box" width={12} color="icon_darkest" left="40%" top="30%" />
				<SVG icon="hexa" width={16} stroke color="icon_darker" left="10%" top="50%" />
				<SVG icon="hexa" width={8} stroke color="icon_darker" left="10%" top="70%" />
			</div>
		</div>
	);
};
export default Contact;
