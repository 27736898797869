import React from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import useSiteMetadata from '../../hooks/use-site-metadata';

type Props = {
	title?: string;
	description?: string;
	pathname?: string;
	image?: string;
	children?: React.ReactNode;
};

const SEO = ({
	title = ``,
	description = ``,
	pathname = ``,
	image = ``,
	children = null,
}: Props) => {
	const site = useSiteMetadata();

	const {
		siteTitle,
		siteTitleAlt: defaultTitle,
		siteUrl,
		siteDescription: siteDescription,
		siteLanguage,
		siteImage: siteImage,
		author,
	} = site;

	const seo = {
		title: title || defaultTitle,
		description: description || siteDescription,
		url: `${siteUrl}${pathname || ``}`,
		image: `${siteImage}`,
	};

	return (
		<Helmet
			title={siteTitle}
			defaultTitle={siteTitle}
			titleTemplate={`%s | ${siteTitle}`}
		>
			<meta charSet="utf-8" />
			<html lang={siteLanguage} />
			<meta name="description" content={seo.description} />
			<meta name="image" content={seo.image} />
			<meta property="og:title" content={seo.title} />
			<meta property="og:url" content={seo.url} />
			<meta property="og:description" content={seo.description} />
			<meta property="og:image" content={seo.image} />
			<meta property="og:type" content="website" />
			<meta property="og:image:alt" content={seo.description} />
			<meta name="gatsby-theme" content="@lekoarts/gatsby-theme-cara" />
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1.0"
			/>
			<meta
				name="keywords"
				content="html5,css3, sass, scss, react, redux, redux-saga, vue, angular, chart.js, wordpress, node, node.js, web, web-development, website, web-development"
			/>
			<meta name="robots" content="index, follow" />

			<link
				rel="icon"
				type="image/png"
				sizes="32x32"
				href={withPrefix(`/favicon-32x32.png`)}
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="16x16"
				href={withPrefix(`/favicon-16x16.png`)}
			/>
			<link
				rel="apple-touch-icon"
				sizes="180x180"
				href={withPrefix(`/apple-touch-icon.png`)}
			/>
			<link
				href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap"
				rel="stylesheet"
				type="text/css"
			></link>

			{children}
		</Helmet>
	);
};

export default SEO;
