import React from 'react';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useColorMode } from 'theme-ui';

import './index.scss';

const ButtonStyles = styled.button`
	padding: 10px 20px;
	font-weight: 600;
	outline: none;
	cursor: pointer;
	transform: scale(1);
	transition: all 0.45s ease-in-out;

	&:hover {
		transform: scale(1.1);
	}
`;

export const Footer = () => {
	const [colorMode, setColorMode] = useColorMode(['dark']);
	const [offset, setOffset] = useState<number>(0);

	const setBodyClass = () => {
		document.body.classList.toggle('light-mode');
	};

	useEffect(() => {
		function handleScroll() {
			setOffset(window.pageYOffset);
		}
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const isDark = colorMode === `dark`;
	const toggleColorMode = () => {
		setBodyClass();
		setColorMode(isDark ? `light` : `dark`);
	};

	return (
		<>
			<div
				className="c-footer"
				style={{
					transform: `translateY(${offset * -0.5}px)`,
					backgroundColor: '',
				}}
			>
				<ButtonStyles
					className="c-btn-toggle-theme-mode"
					sx={{
						variant: `buttons.toggle`,
						fontWeight: `semibold`,
						display: `block`,
						mx: `auto`,
						mb: 3,
					}}
					onClick={toggleColorMode}
					type="button"
					aria-label="Toggle dark mode"
				>
					{isDark ? `Light` : `Dark`}
				</ButtonStyles>

				<p>
					&copy; <strong>Three11</strong> {new Date().getFullYear()}
				</p>
			</div>
		</>
	);
};

export default Footer;
