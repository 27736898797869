export const Facebook = require('./icon-facebook.svg');
export const Github = require('./icon-github.svg');
export const Linkedin = require('./icon-linkedin.svg');
export const Twitter = require('./icon-twitter.svg');

export const ChartJs = require('./icon-chartjs.svg');
export const CSS3 = require('./icon-css3.svg');
export const HTML5 = require('./icon-html5.svg');
export const JavaScript = require('./icon-javascript.svg');
export const Nuxt = require('./icon-nuxt.svg');
export const React = require('./icon-react.svg');
export const Redux = require('./icon-redux.svg');
export const ReduxSaga = require('./icon-redux-saga.svg');
export const SCSS = require('./icon-sass.svg');
export const Vue = require('./icon-vue.svg');
export const WordPress = require('./icon-wordpress.svg');
