import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSprings, animated } from 'react-spring';

import { useStaticQuery, graphql } from 'gatsby';

import * as Images from '../../assets/images/temp';
import * as SVG from '../../assets/images/svg';

import './index.scss';

export const Projects = () => {
	const data = useStaticQuery(graphql`
		query NonPageQuery {
			allProject {
				nodes {
					alt
					name
					src
					url
					technologies
				}
			}
		}
	`);

	const [offset, setOffset] = useState(0);
	const [isTabletPortrait, setIsTabletPortrait] = React.useState(false);
	const [isTabletLandscape, setIsTabletLandscape] = React.useState(false);

	const calc = (x, y) => [
		-(y - window.innerHeight / 2) / 20,
		(x - window.innerWidth / 2) / 20,
		1.05,
	];
	const trans = (x, y, s) =>
		`perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

	const [props, set] = useSprings(
		data.allProject.nodes.length,
		(i: number) => ({
			xys: [0, 0, 1],
			config: { mass: 5, tension: 350, friction: 40 },
		})
	);

	useEffect(() => {
		setIsTabletPortrait(window.innerWidth < 1024);
		setIsTabletLandscape(window.innerWidth < 1280);

		function handleScroll() {
			setOffset(window.pageYOffset);
		}
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<section
			id="projects"
			className={`c-projects-list`}
			style={{
				transform: `translateY(${offset * -0.5}px)`,
				backgroundColor: '',
			}}
		>
			<h2>Recent Work</h2>

			<div className="c-projects__wrapper ">
				{data.allProject.nodes.map((project, key) => {
					return (
						<div
							className="c-project"
							key={key}
							data-offset={`${key % 2 === 0 ? '0' : '90'}  `}
						>
							<animated.div
								className="cards"
								onMouseMove={
									isTabletLandscape || isTabletPortrait
										? () => {
												set({ xys: [0, 0, 1] });
										  }
										: ({ clientX: x, clientY: y }) => {
												set((i) => {
													if (i !== key) return;
													return { xys: calc(x, y) };
												});
										  }
								}
								onMouseLeave={() => set({ xys: [0, 0, 1] })}
								style={{
									transform: props[key].xys.interpolate(
										trans as any
									),
								}}
							>
								<figure className="c-project__image">
									<a
										target="_blank"
										rel="noreferrer"
										href={project.url}
									>
										<img
											src={Images[`${project.src}`]}
											alt={`${project.alt}`}
										/>

										<span>View</span>
									</a>
								</figure>
							</animated.div>

							<div className="c-project__description">
								<h4 className="c-project__title">
									{project.name}
								</h4>
								{/* <h5 className="c-project__subtitle">Technologies used:</h5> */}

								<p>
									Lorem, ipsum dolor sit amet consectetur
									adipisicing elit. Similique, nobis odio nisi
									mollitia totam porro impedit aperiam
									pariatur reprehenderit, neque asperiores
									rerum ipsa, consequuntur facere. Hic illo
									asperiores explicabo facilis.
								</p>

								<ul className="c-project__technologies">
									{project.technologies.map((technology) => (
										<li
											className="c-project__technology"
											key={technology}
										>
											<img
												title={technology}
												src={SVG[`${technology}`]}
												alt={`${technology} logo`}
											/>
										</li>
									))}
								</ul>
							</div>
						</div>
					);
				})}
			</div>
		</section>
	);
};

export default Projects;
